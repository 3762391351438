require('./bootstrap');
require('./dashboard/Select2 4.1.0.min');

$('.onClickSetDisabled').each((key, item) => {
    $(item).on('click', function () {
        setTimeout(() => {
            $(this).attr('disabled', true)
        }, 0);
    })
});

$('footer p.footer-copyright > span:first-child').text('© ' + (new Date()).getFullYear())

document.cookie = `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}; path=/`;

window.getCookie = (key, defaultVal = null) => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return defaultVal;
}

window.setCookie = (key, value, time) => {
    const d = new Date();
    d.setTime(d.getTime() + time);
    let expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
}

if (!window.getCookie('hideCookieBox')) {
    $('.cookieBox').slideDown(300);
}

$('.closeCookieBox').on('click', function () {
    $('.cookieBox').slideUp(200);
    window.setCookie('hideCookieBox', 1, 60 * 24 * 60 * 60 * 1000) // 2 Months
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('.loginWithAction').on('click', function () {
    if ($(this).data('callback')) {
        $('#loginModalForm').attr('data-callback', $(this).data('callback'));
        $('#registerModalForm').attr('data-callback', $(this).data('callback'));

        const callbackUrl = new URL($(this).data('callback'));
        const path = encodeURIComponent(callbackUrl.pathname.replace(/^\/+/, '') + callbackUrl.search);
        $('.login_provider_button').each((index, element) => {
            const buttonUrl = new URL(element.href);
            buttonUrl.searchParams.set('redirect', path);
            element.href = buttonUrl.href;
        })

    } else if ($(this).data('callback-element')) {
        $('#loginModalForm').attr('data-callback-element', $(this).data('callback-element'));
        $('#registerModalForm').attr('data-callback-element', $(this).data('callback-element'));
    }
})

$('#loginModalForm, #loginAdminModalForm').on('submit', function (e) {
    e.preventDefault();
    const form = $(this);
    const formData = form.serializeArray();
    const loginBtn = form.find('button[type=submit]');
    loginBtn.attr('disabled', true);
    clearFormErrors(form);

    const data = {
        email: formData[1].value,
        password: formData[2].value,
        remember: formData[3]?.value ?? null,
    }

    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        headers: {
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: JSON.stringify(data),
        success: res => onLoginRegisterSuccess($(this), res),
        error: err => onLoginRegisterError(form, err, loginBtn)
    })
});

$('#registerModalForm').on('submit', async function (e) {
    e.preventDefault();
    const form = $(this);
    const registerBtn = form.find('button[type=submit]');
    registerBtn.attr('disabled', true);
    $('.loader').addClass('show');
    clearFormErrors(form);
    const formData = new FormData(this);

    if (typeof grecaptcha !== "undefined") {
        grecaptcha.ready(function() {
            getRecaptchaToken().then(async ({token, key}) => {

                formData.set(key, token);

                await sendRegister(form, formData, registerBtn);
            });
        });
    } else {
        await sendRegister(form, formData, registerBtn);
    }
});
window.sendRegister = async function(form, formData, registerBtn) {
  try {
      const res =  await axios({
          url: form.attr('action'),
          method: form.attr('method'),
          data: formData,
      });

      if (res.status === 200) {
          onLoginRegisterSuccess(form);

          return true;
      }

      return false;
  } catch (error) {
      const errors = error.response.data.errors;

      for (let key in errors) {
          if (key.startsWith('custom.')) {
              const inputGroupId = key.replace(/^custom\.(.*)\.values?$/, 'register_custom_$1')
              const inputGroupElement = form.find(`#${inputGroupId}`);

              inputGroupElement.find('.custom-validatable').addClass('is-invalid');
              inputGroupElement.find('.invalid-feedback-wrapper')
                  .append(`<span class="invalid-feedback d-block" role="alert">
                             <strong> ${errors[key][0]}</strong>
                        </span>`);
          } else {
              form.find(`.validatable[name="${key}"]`)
                  .addClass('is-invalid')
                  .after(`<span class="invalid-feedback" role="alert">
                             <strong> ${errors[key][0]}</strong>
                        </span>`);
          }
      }

      if (typeof recaptchaV2 !== 'undefined' && recaptchaV2) {
          grecaptcha.reset();
      }
      if (errors['g-recaptcha-3-response']) {
          recaptchaV2Render();
      }

      $('.loader').removeClass('show');
      registerBtn.attr('disabled', false);

      return false;
  }
}

$("#loginModal").on("hidden.bs.modal", function () {
    clearFormErrors($(this));
});

$("#registerModal").on("hidden.bs.modal", function () {
    clearFormErrors($(this));
});

$(".toggle-password").click(function () {
    $(this).toggleClass("eye-slash");
    let input = $($(this).attr("toggle"));

    if (input.attr("type") === "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

$('#contact-form-grapesjs, .contact-form-grapesjs').on('submit', function (e) {
    e.preventDefault();
    const form = $(this);
    const btn = form.find('button[type=submit]');
    btn.attr('disabled', true);
    $('.loader').addClass('show');
    clearFormErrors(form);

    const formData = new FormData(this);

    const data = {};
    formData.forEach((value, key) => (data[key] = value));
    data['ignore'] = 1;

    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        headers: {
            'Content-type': 'application/json',
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: JSON.stringify(data),
        success: res => {
            window.location.reload();
        },
        error: err => onLoginRegisterError(form, err, btn),
    });
});

window.clearFormErrors = function(parent) {
    parent.find('.invalid-feedback').remove();
    parent.find('.is-invalid').removeClass('is-invalid')
}


window.addTranslationMessages = (messages) => {
    if (_.isObject(messages)) {
        window.translationMessages = messages;
        return;
    }

    messages.forEach((message) => {
        window.translationMessages[message.key] = message.value;
    })
}
window.trans = (key, group = null) => {
    if (translationMessages[key]) {
        return translationMessages[key];
    }

    return (group ? group + '.' : null) + key;
}
toastr.options = {
    "closeButton": true,
    "progressBar": true,
    "showDuration": "500",
    "hideDuration": "1000",
    "timeOut": "5000"
};

toastr.danger = function (options) {
    return toastr.error(options);
}

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

window.addEventListener('resize', appHeight)
appHeight()

$('.tab[data-tab]').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
});

$('*[data-accordion-container]').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');

    setTimeout(() => {
        if ($(this).hasClass('active')) {
            $(this).find('.accordion-content').css({display: 'block'})
        } else {
            $(this).find('.accordion-content').css({display: 'none'})
        }
    });
});

$('.accordion-content').on('click', function (e) {
    e.stopPropagation();
})

$('.edit-page-builder').tooltip();


$('.social-share-btn').on('click',
    function (e) {
    e.preventDefault()
        $('.course-share-social-block').toggleClass('social-share-open');
});
